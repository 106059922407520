import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import BookingOptionButton from 'components/BookingDetail2/UI/BookingOptionButton';
import Appointment, { status } from '@spike/appointment-model';
import { ButtonStatusAction } from './UI/ButtonStatusAction';
import Booking from '@spike/booking-model';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
/* import ButtonStatusActions from "./UI/ButtonStatus/ButtonStatusActions";*/

interface BookingDetailHeaderProps {
    title: string;
    booking?: Booking;
    appointment?: Appointment;
    uniquePet?: boolean;
    loading: boolean;
    appointmentToConfirm?: Appointment;
    withoutReschedule?: boolean;
    onClose?: () => void;
    onBack?: () => void;
    onBook: () => void;
    onRebook: (selectedBooking: Booking) => void;
    onCancel: (appointmentId: number) => void;
    onCancelBooking: () => void;
    onCheckIn: () => void;
    onCheckOut: () => void;
    onComplete: (appointment: Appointment) => void;
    onConfirm: () => void;
    onNoShow: (appointmentId: number) => void;
    onDecline: (appointment: Appointment) => void;
    onRescheduleAppointment: () => void;
    onRescheduleBooking: () => void;
    onShowInvoice: () => void;
    onShowReport: (appointment: Appointment) => void;
    onUndo: (appointment: Appointment, statusId: number) => void;
    onShowAddReport?: (
        appointmentId: number,
        businessAreaId: string,
        clientId: number
    ) => void;
    onChangeStatus: (status: number) => void;
    onStart: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            height: 100,
            width: '100%'
        },
        titleContainer: {
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center'
        },
        actionsContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        title: {
            fontWeight: 600,
            fontSize: 20,
            lineHeight: '20px',
            [theme.breakpoints.up('md')]: {
                fontSize: 22,
                lineHeight: '22px'
            }
        },
        spacing: {
            paddingRight: 16,
            [theme.breakpoints.up('md')]: {
                paddingRight: 20
            }
        },
        icon: {
            cursor: 'pointer',
            fontSize: 22,
            [theme.breakpoints.up('md')]: {
                fontSize: 24
            }
        },
        buttonContainer: {
            display: 'flex',
            width: '80%',
            marginRight: 15
        },
        optionsContainer: {
            width: '20%'
        },
        iconClose: {
            cursor: 'pointer',
            width: 20,
            height: 20,
            fontWeight: 300,
            paddingLeft: 5,

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        block: {
            display: 'block !important'
        }
    })
);

export const BookingDetailHeader: FunctionComponent<
    BookingDetailHeaderProps
> = props => {
    const classes = useStyles(props);

    return (
        <Box className={classes.headerContainer}>
            <Box className={classes.titleContainer}>
                {props.onBack && (
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        className={clsx(classes.icon, classes.spacing)}
                        onClick={props.onBack}
                    />
                )}
                <Typography className={classes.title}>{props.title}</Typography>
            </Box>
            <Box className={classes.actionsContainer}>
                {/* {!props.onBack && props.appointment && props.uniquePet && 
          <Box className={classes.buttonContainer}>
            <ButtonStatusActions 
              appointment={props.appointment}
              onChangeStatus={props.onChangeStatus}
            />
          </Box>} */}
                {!props.onBack &&
                    props.appointment &&
                    (props.booking?.appointments.length === 1 ||
                        props.appointment?.status.id === status.BOOKED ||
                        props.appointmentToConfirm) &&
                    ![status.READY_FOR_CHECK_OUT, status.REQUESTED].includes(
                        props.appointment.status.id
                    ) && (
                        <Box className={classes.buttonContainer}>
                            <ButtonStatusAction
                                loadingAction={props.loading}
                                appointment={
                                    props.appointmentToConfirm ??
                                    props.appointment
                                }
                                onCheckIn={props.onCheckIn}
                                onCheckOut={props.onCheckOut}
                                onComplete={() =>
                                    props.onComplete(props.appointment!)
                                }
                                onConfirm={props.onConfirm}
                                onStart={props.onStart}
                            />
                        </Box>
                    )}
                {!props.onBack &&
                    props.appointment &&
                    props.appointment.status.id !== status.REQUESTED &&
                    (props.booking?.appointments.length === 1 ||
                        /* (props.booking && props.booking?.appointments?.length > 0 || */
                        props.appointment?.status.id === status.BOOKED ||
                        props.appointmentToConfirm) && (
                        <Box
                            className={classes.optionsContainer}
                            id="edit_div_action"
                        >
                            <BookingOptionButton
                                isDetail={true}
                                appointment={
                                    props.appointmentToConfirm ??
                                    props.appointment
                                }
                                withoutReschedule={props.withoutReschedule}
                                singleBooking={
                                    props.booking?.appointments.length === 1
                                }
                                onBook={props.onBook}
                                onRebook={() => props.onRebook(props.booking!)}
                                onCancel={() =>
                                    props.onCancel(props.appointment!.id!)
                                }
                                onCancelBooking={props.onCancelBooking}
                                onDecline={() =>
                                    props.onDecline(props.appointment!)
                                }
                                onNoShow={() =>
                                    props.onNoShow(props.appointment!.id!)
                                }
                                onRescheduleAppointment={
                                    props.onRescheduleAppointment
                                }
                                onRescheduleBooking={props.onRescheduleBooking}
                                onShowReport={() =>
                                    props.onShowReport(props.appointment!)
                                }
                                onShowInvoice={props.onShowInvoice}
                                onUndo={(
                                    appointmentId: number,
                                    statusId: number
                                ) => props.onUndo(props.appointment!, statusId)}
                                onShowAddReport={props.onShowAddReport}
                            />
                        </Box>
                    )}
                {!props.onBack && (
                    <FontAwesomeIcon
                        icon={faTimes}
                        className={clsx(classes.iconClose, {
                            [classes.block]:
                                props.appointment?.status.id ===
                                status.REQUESTED
                        })}
                        onClick={props.onClose}
                    />
                )}
            </Box>
        </Box>
    );
};

export default BookingDetailHeader;
